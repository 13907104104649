.about-container{
    display: grid;
    grid-template-columns: 35% 50%;
    /* align-items: center; */
    gap: 15%;
}

.about-me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: center;
}

.about-me-image{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about-me-image:hover{
    transform: rotate(0);
}

.about-content p{
    margin: 1rem 0 1rem;
    /* margin-bottom: 0.5rem; */
    color: var(--color-light);
}


/* media query (medium devices) */
@media screen and (max-width: 1024px) {
    .about-container{
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about-me{
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about-content p{
        margin: 1rem 0 1rem;
    }
  }
  
  /* media query (small devices) */
  @media screen and (max-width: 600px) {
    .about-me{
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about-content{
        text-align: center;
    }

    .about-content p{
        margin: 1.5rem 0 ;
    }
  }